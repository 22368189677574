var render = function render(){var _vm=this,_c=_vm._self._c;return _c('nav',{staticClass:"min-w-1400",class:{
    navbar: _vm.$route.path === '/',
    'other-navbar': _vm.$route.path !== '/',
  }},[_c('div',{staticClass:"max-w-1400",class:{
      secondBox: _vm.$route.path === '/',
      'other-secondBox': _vm.$route.path !== '/',
    }},[_vm._m(0),_c('ul',{staticClass:"nav-links"},[_c('li',{staticClass:"Li1",class:{ 'active-link': _vm.isActive('/') }},[_c('router-link',{staticClass:"nav-link1",attrs:{"to":"/"}},[_c('span',{staticClass:"routeText1"},[_vm._v("Inicio")])])],1),_c('li',{staticClass:"Li2",class:{ 'active-link': _vm.$route.path === '/about' }},[_c('router-link',{staticClass:"nav-link2",attrs:{"to":"/about"}},[_c('span',{staticClass:"routeText2"},[_vm._v("Acerca de")])])],1),_c('li',{staticClass:"Li3",class:{ 'active-link': _vm.$route.path === '/introduce' }},[_c('router-link',{staticClass:"nav-link3",attrs:{"to":"/introduce"}},[_c('span',{staticClass:"routeText3"},[_vm._v("Contacto")])])],1),_c('li',{staticClass:"Li4",class:{ 'active-link': _vm.$route.path === '/protocol' }},[_c('router-link',{staticClass:"nav-link4",attrs:{"to":"/protocol"}},[_c('span',{staticClass:"routeText4"},[_vm._v("Política de Privacidad")])])],1)])]),_c('div',{staticStyle:{"height":"130px"}})])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"logo"},[_c('span',{staticClass:"title"},[_vm._v("Desarrollo Orígenes Fofor, S.A.P.I., de C.V.SOFOM, E.N.R.")])])
}]

export { render, staticRenderFns }